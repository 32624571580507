.landing--CTA {
    background: url('../images/landingPageImage.jpg') center center/cover no-repeat;
    height:90vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
}

.landing--CTA > span {
    color: #fff;
    font-size: 40px;
    margin-bottom: 20px;
    margin-right: 10px;
    display: inline-block;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#btn--CTA {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    margin-bottom: 30px;
    border: 1px solid;
    transition: all 0.3s ease-out;
}

#btn--CTA:hover
{
    cursor: pointer;
}

.description {
    background-color: #98ff98;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.description > h1 {
    margin-top: 10px;
}

.description > p {
    border: 2px solid black;
    margin: 10px 30px;
    padding: 10px;
    background-color: #fff;
    max-width: 70%;
}