.backdrop{
    /* dim the background when a modal is brought up */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
}

.backdrop img{
    display: block;
    max-width: 60vh;
    max-height: 80vw;
    /* 60px top and bottom, left/right is auto */
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
    border: 3px solid #98ff98;
}