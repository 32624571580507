* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.img-grid{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background-color: dimgray;
    height: 100vh;
    background: url('../images/portfolioBG.jpg') center center/cover no-repeat;
}

.img-card{
    /* width: 270px; */
    flex-basis: 300px;
    /* width: 30vw; */
    height: 400px;
    /* height: 40vh; */
    margin: 10px;
    border-radius: 15px;
    background-color: #98ff98;
    /* properly format and display text/element overflow
        you can specify whether the overflow is hidden or
        accessible via scrollbar/ellipses. */
    overflow: auto;
}

.img-card img{
    /* height: 150px; */
    height: 60%;
    /* can stretch the img, a little */
    width: 100%;
     /* contain keeps the aspect ratio of the original photo
        cover will zoom in the image but cover the space. */
    object-fit: cover;
    margin-bottom: 15px;
    border-radius: 15px 15px 0 0;
}

.img-card p{
    padding: 0px 5px;
}

.img-card:hover{
    transform: scale(1.05);
    transition: all 0.5s ease;
    cursor: pointer;
}

.tag-bubble{
    background-color: rgb(153, 152, 152);
    display: inline-block;
    border-radius: 5px;
    margin-right: 5px;
    padding: 2px;
}