.about-container{
    background-color: black;
}

.about-media
{
    background: url('../images/aboutCat.jpg') center center/cover no-repeat;
    height: 30vh;
    width: 100%;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
}

.about-description{
    background-color: #98ff98;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.about-description > h1 {
    margin-top: 10px;
}

.about-description > p {
    border: 2px solid black;
    margin: 10px 30px;
    padding: 10px;
    background-color: #fff;
    max-width: 70%;
}