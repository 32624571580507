.container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 10%;
    background-color: #98ff98;
    font-size: 1.2rem;
}

.logo{
    max-width: 5%;
    height: auto;
    width: auto;
    justify-content: flex-start;
    padding: 5px;
    cursor: pointer;
    /* can set a background color to see changes or bug-fix */
    /* background-color: red; */
}


